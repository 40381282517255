import { Routes } from '../constants';
import completeRegistrationData from '../constants/completeRegistrationData';
import { currencyList } from '../constants/currencyList';

export const getCampaignListRedirectPath = (isAdmin, pathName) => {
  let redirectPath = '';
  if (isAdmin && pathName.indexOf('/ngo/project/list') > -1) {
    redirectPath = Routes.NGO.ADMIN_CAMPAIGN_LIST;
  } else if (!isAdmin && pathName.indexOf('/ngo/project/all-list') > -1) {
    redirectPath = Routes.NGO.ADMIN_CAMPAIGN_LIST;
  }
  return redirectPath;
};

export const getCloudinaryUrlForImage = (originalUrl, height) => {
  return (
    originalUrl &&
    originalUrl.replace(
      'https://content.mettasocial.com/content/',
      `https://res.cloudinary.com/mettasocial/image/upload/h_${height},e_sharpen/public/`
    )
  );
};

export const restrictToNumber = value => {
  value = value.replace(/\D/g, '');
  if (value) {
    value = Number(value);
  } else {
    value = 0;
  }
  return value;
};

export const eventToValue = (e, trimResults, toNumber) => {
  let { target = {} } = e;
  let { value = '' } = target;
  if (toNumber && value !== '') {
    value = Number(value);
  } else {
    value = value || '';
    if (value && trimResults) {
      value = value.trim();
    }
  }
  return value;
};

export const createMarkup = value => {
  return { __html: value };
};

export const checkIfStepSaved = (data, step) => {
  const wrapperKey = completeRegistrationData[parseInt(step) - 1].wrapperKey;
  let stepData = data[wrapperKey];
  let isSaved = false;

  for (let key in stepData) {
    if (
      (Array.isArray(stepData[key]) && stepData[key].length > 0) ||
      typeof stepData[key] === 'boolean' ||
      typeof stepData[key] === 'string'
    ) {
      isSaved = true;
    }
  }
  return isSaved;
};

export const getDate = value => {
  const dateObj = new Date(value);
  if (isNaN(dateObj)) {
    return '';
  }
  const day = dateObj
    .getDate()
    .toString()
    .padStart(2, '0');
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObj.getFullYear();
  return `${day}/${month}/${year}`;
};

export const getTime = value => {
  let dateValue = new Date(value);
  return `${dateValue.getHours()}:${dateValue.getMinutes()}`;
};

export const getFormattedDate = (format, value) => {
  let date = '';
  if (format === 'dd/MM/yyyy') {
    date = `${getDate(value)}`;
  } else if (format === 'dd/MM/yyyy | hh/mm') {
    date = `${getDate(value)} | ${getTime(value)}`;
  } else if (format === 'dd MMM yyyy') {
    const valueDate = new Date(value);
    const formatter = new Intl.DateTimeFormat('en', { month: 'short' });
    const month = formatter.format(valueDate);
    date = valueDate.getDate() + ' ' + month + ' ' + valueDate.getFullYear();
  }
  return date;
};

export const getLimitedCharString = (
  value,
  charLimit,
  mobCharLimit,
  tabCharLimit
) => {
  let title = '';
  let characterLimit = charLimit;
  const windowWidth = window.innerWidth;
  if (windowWidth < 768 && mobCharLimit) characterLimit = mobCharLimit;
  if (windowWidth >= 768 && windowWidth < 992 && tabCharLimit)
    characterLimit = tabCharLimit;
  title =
    value.length > characterLimit
      ? `${value.substring(0, characterLimit + 1).trimEnd()}...`
      : value;
  return title;
};

export const getCurrencyIcon = currencyName => {
  if (!currencyName || currencyName === '') return '';
  if (currencyName) {
    const currency = currencyList.find(item => item.code === currencyName);
    return currency ? currency.symbol : '';
  }
};
